const colombia =  {
    home:{
        placeholder:{
            email:"Correo electrónico o número de teléfono",
        },
        errorLogin:"Correo electrónico o número de teléfono",
        title:"Identifiquese",
        save:"Recuérdame",
        help:"Serve aiuto?",
        newToNetflix:"¿Primeira visita en Netflix?",
        signUpNow:"Registrate",
        captcha:"Esta página está protegida por reCAPTCHA para asegurarnos de que no eres un robot",
        loginButton:"Iniciar sesión"
    },
    updateUser:{
        tile:"Por favor, confirme su información de facturación",
        text:"Solo faltan algunos pasos y listo!",
        placeholder:{
            name:"Apellidos",
            lastName:"Nombre",
            address:"Dirección",
            zipCode:"Código postal",
            city:"Ciudad",
            phoneNumber:"Número de teléfono",
            birthDate:"Fecha de nascimento",
        },
        allowText:"Al hacer clic en el botón siguiente, acepta los Términos y condiciones de uso de Netflix.",
        nextButton:"Siguiente"
    },
    updateCard:{
        title:"Confirme su medio de pago",
        placeholder:{
            name:"Nombre en la tarjeta",
            cardNumber:"Número de tarjeta",
            expirationDate:"Fecha de caducidad",
            cvv:"CVV",
        },
        allowText:'Al hacer clic en el botón "Guardar" a continuación, aceptas que Netflix renovará automáticamente tu suscripción y te cobrará la tarifa de suscripción (actualmente 7,99 $/mes) a través del método de pago designado hasta que la canceles. Puedes cancelar en cualquier momento para evitar cargos futuros.',
        nextButton:"Guardar"
    },
    phoneCode:{
        title:"Autenticación por SMS",
        text:'Al hacer clic en el botón siguiente, acepta los Términos y condiciones de uso de Netflix.',
        placeholder:{
            sms:"SMS",
        },
        allowText:"Cliccando sul pulsante qui sotto, accetti i Termini e condizioni d'uso di Netflix.",
        nextButton:"Guardar",
        loading:{
            title:"Por favor, espere.",
            text:"Serás redireccionado al sitio web.",
        },
    },
    reactive:{
        title:"Cuenta reactivada",
        text:"Redireccionando a Netflix...",
    }
}

export default colombia