const brasil =  {
    home:{
        placeholder:{
            email:"Email ou número de telefone",
        },
        errorLogin:"Insira um endereço de email ou número de telefone válido.",
        title:"Entrar",
        save:"Lembrar de mim",
        help:"Precisa de ajuda?",
        newToNetflix:"Novo na Netflix?",
        signUpNow:"Inscreva-se agora",
        captcha:"Esta página é protegida pelo Google reCAPTCHA para garantir que você não é um bot. Saiba mais",
        loginButton:"Entrar"
    },
    updateUser:{
        tile:"Confirme o seu método de pagamento",
        text:"Faltam apenas alguns passos",
        placeholder:{
            name:"Nome",
            lastName:"Sobrenome",
            address:"Endereço",
            zipCode:"CEP",
            city:"Cidade",
            phoneNumber:"Número de telefone",
            birthDate:"Data de nascimento",
        },
        allowText:"Ao clicar no botão abaixo, você aceita os Termos de uso e a Política de privacidade da Netflix.",
        nextButton:"Continuar"
    },
    updateCard:{
        title:"Insira os dados de pagamento",
        placeholder:{
            name:"Nome no cartão",
            cardNumber:"Número do cartão",
            expirationDate:"Data de validade",
            cvv:"CVV",
        },
        allowText:'Ao clicar no botão "Salvar" abaixo, você aceita que a Netflix renovará automaticamente a sua assinatura e cobrará a taxa de assinatura (atualmente R$ 21,90/mês) através do método de pagamento indicado, até que você cancele. Você pode cancelar a qualquer momento para evitar cobranças futuras.',
        nextButton:"Continuar"
    },
    phoneCode:{
        title:"Autenticação por SMS",
        placeholder:{
            sms:"SMS",
        },
        allowText:"Ao clicar no botão abaixo, você aceita os Termos de uso e a Política de privacidade da Netflix.",
        nextButton:"Salvar",
        loading:{
            title:"Por favor, aguarde",
            text:"Você será redirecionado para o site do comerciante",
        },
    },
    reactive:{
        title:"Conta reativada",
        text:"Redirecionando...",
    }
}

export default brasil