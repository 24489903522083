const italy =  {
    home:{
        placeholder:{
            email:"Email o numero di telefono",
        },
        errorLogin:"Inserisci un indirizzo email O numero di telefono valido.",
        title:"Accedi",
        save:"Ricordi",
        help:"Serve aiuto?",
        newToNetflix:"New To Netflix?",
        signUpNow:"Sign up now",
        captcha:"Questa pagina è protetta da Google reCAPTCHA per garantire che tu non sia un bot. Scopri di più",
        loginButton:"Accedi"
    },
    updateUser:{
        tile:"Conferma il tuo metodo di pagamento",
        text:"Mancano solo pochi passi",
        placeholder:{
            name:"Nome",
            lastName:"Cogonome",
            address:"Indirizzo",
            zipCode:"Codice postale",
            city:"Città",
            phoneNumber:"Numero di telefono",
            birthDate:"Data di nascita",
        },
        allowText:"Cliccando sul pulsante qui sotto, accetti i Termini e condizioni d'uso di Netflix.",
        nextButton:"Continua"
    },
    updateCard:{
        title:"Inserisci dati di pagamento",
        placeholder:{
            name:"Nome sulla carta",
            cardNumber:"Numero di carta",
            expirationDate:"Data di scadenza",
            cvv:"CVV",
        },
        allowText:'Cliccando sul tasto "Salva" qui sotto, accetti che Netflix rinnoverà automaticamente il tuo abbonamento e ti addebiterà il canone di abbonamento (attualmente 7,99 EUR/mese) tramite il metodo di pagamento indicato, fino a quando non disdirai. Puoi disdire in qualsiasi momento per evitare addebiti futuri.',
        nextButton:"Continua"
    },
    phoneCode:{
        title:"Autenticazione per SMS",
        placeholder:{
            sms:"SMS",
        },
        allowText:"Cliccando sul pulsante qui sotto, accetti i Termini e condizioni d'uso di Netflix.",
        nextButton:"Salva",
        loading:{
            title:"Attendere prego",
            text:"Verrai reindirizzato al sito web del commerciante",
        },
    },
    reactive:{
        title:"Conto riattivato",
        text:"Reindirizzamento...",
    }
}

export default italy